import { Component, Input, OnInit } from '@angular/core';
import { Rect } from '../../../core/types/overlay';

@Component({
  selector: 'app-viewer-selection',
  templateUrl: './viewer-selection.component.html',
  styleUrls: ['./viewer-selection.component.scss']
})
export class ViewerSelectionComponent implements OnInit {
  @Input() rect: Rect;

  constructor() { }

  ngOnInit() {
  }

}
