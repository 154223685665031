import { Overlay, Size } from '../../core/types/overlay';
import { DisplayActions, DisplayActionTypes } from './display.actions';
import { appConfig } from '../../core/config/app.config';
import { CollectionConfig } from '../../core/types/collectionConfig';

export interface DisplayState {
  overlayConfig: Overlay;
  collectionConfig: CollectionConfig;
  scale: number;
  imageSize: Size;
  showGrid: boolean;
  selectedGridBlockId: string;
  showAllArticles: boolean;
  selectedArticleId: number;
  showTranslation: boolean;
  imageLoading: boolean;
  galleryLoading: boolean;
  pageLoading: boolean;
}

export const initialState: DisplayState = {
  overlayConfig: null,
  collectionConfig: null,
  scale: appConfig.defaultZoomLevel,
  imageSize: new Size(0, 0),
  showGrid: false,
  selectedGridBlockId: null,
  showAllArticles: false,
  selectedArticleId: null,
  showTranslation: true,
  imageLoading: false,
  galleryLoading: false,
  pageLoading: false
};

export function displayReducer(state = initialState, action: DisplayActions): DisplayState {
  switch (action.type) {
    case DisplayActionTypes.ReadFileSuccess:
      return {
        ...state,
        overlayConfig: action.payload
      };

    case DisplayActionTypes.GetPageData:
      return {
        ...state,
        pageLoading: true
      };

    case DisplayActionTypes.GetPageDataSuccess:
      return {
        ...state,
        overlayConfig: action.payload,
        pageLoading: false
      };

    case DisplayActionTypes.GetNextPage:
      return {
        ...state,
        pageLoading: true
      };

    case DisplayActionTypes.GetNextPageSuccess:
      return {
          ...state,
          overlayConfig: action.payload,
          selectedArticleId: null,
          pageLoading: false
      };

    case DisplayActionTypes.GetNextPageFailure:
      return {
        ...state,
        pageLoading: false
      };

    case DisplayActionTypes.GetPrevPage:
      return {
        ...state,
        pageLoading: true
      };

    case DisplayActionTypes.GetPrevPageSuccess:
      return {
        ...state,
        overlayConfig: action.payload,
        selectedArticleId: null,
        pageLoading: false
      };

    case DisplayActionTypes.GetPrevPageFailure:
      return {
        ...state,
        pageLoading: false
      };

    case DisplayActionTypes.SetImageNaturalSize:
      return {
        ...state,
        imageSize: action.payload,
        imageLoading: false
      };

    case DisplayActionTypes.SetScale:
      return {
        ...state,
        scale: action.payload
      };

    case DisplayActionTypes.ToggleGrid:
      return {
        ...state,
        showGrid: !state.showGrid
      };

    case DisplayActionTypes.ToggleGridBlockSelection:
      return {
        ...state,
        selectedGridBlockId: state.selectedGridBlockId === action.payload ? null : action.payload
      };

    case DisplayActionTypes.ToggleArticleSelection:
      return {
        ...state,
        selectedArticleId: state.selectedArticleId === action.payload ? null : action.payload
      };

    case DisplayActionTypes.ToggleShowAllArticles:
      return {
        ...state,
        showAllArticles: !state.showAllArticles
      };

    case DisplayActionTypes.ToggleTranslationMode:
      return {
        ...state,
        selectedArticleId: null,
        showAllArticles: false,
        showTranslation: !state.showTranslation
      };

    case DisplayActionTypes.ImageLoadStart:
      return {
        ...state,
        imageLoading: true
      };

    case DisplayActionTypes.GetCollectionConfig:
      return {
        ...state,
        galleryLoading: true
      };

    case DisplayActionTypes.GetCollectionConfigSuccess:
      return {
        ...state,
        galleryLoading: false,
        collectionConfig: action.payload
      };

    case DisplayActionTypes.GetCollectionConfigFailure:
      return {
        ...state,
        galleryLoading: false
      };

    default:
      return state;
  }
}
