import {
  Component, ElementRef,
  EventEmitter,
  Input, ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { appConfig } from '../../../core/config/app.config';
import { Language } from '../../../core/config/config.model';
import { Globals } from '../../../core/globals';
import { GetCollectionConfig } from '../../display/display.actions';
import { AppStateWithDisplay } from '../../display/display.selectors';
import { MenuService } from '../../../core/service/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Input() pageRouter: string;
  @ViewChild('fileInput') fileInput: ElementRef;

  languages: Language[];
  scale: number = appConfig.defaultZoomLevel;

  constructor(
      private router: Router,
      private store: Store<AppStateWithDisplay>,
      public translate: TranslateService,
      private menuService: MenuService,
      private globals: Globals
  ) {
    this.languages = appConfig.languageList;
    for (const language of this.languages) {
      translate.addLangs([language.value]);
    }
    translate.use(globals.USER_DESIRED_UI_LANGUAGE);
  }

  showHome() {
    this.router.navigate(['/']);
  }

  showMainGallery() {
    // this.store.dispatch(new GetCollectionConfig('correio-de-africa'));
    this.router.navigate(['/gallery']);
  }


  loadSingleIssue() {
    this.globals.pageUrl = this.globals.demoUrl;
    if (this.pageRouter !== 'display') {
      this.router.navigate(['/display']);
    } else {
      this.menuService.menuLoadSingleIssue(this.globals.demoUrl);
    }
  }

  toggleTranslationMode() {
    this.menuService.toggleTranslationMode();
  }

  toggleShowAllTranslation() {
    this.menuService.toggleShowAllTranslation();
  }

  toggleGrid() {
    this.menuService.toggleGrid();
  }

  uploadJSON() {
    this.fileInput.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  changeLanguage(lang) {
    this.globals.USER_DESIRED_UI_LANGUAGE = lang;
    this.globals.USER_DESIRED_TARGET_LANGUAGE = lang;
    this.translate.use(lang);
  }

  selectionChange(event) {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    if (file) {
      this.menuService.uploadJSON(file);
      this.fileInput.nativeElement.value = null;
    }
  }

  scaleSliderChange(eventValue) {
    this.menuService.changeMenuZoomLevel(eventValue);
  }
}
