import {GalleryActions, GalleryActionTypes} from './gallery.actions';
import {CollectionConfig} from '../../core/types/collectionConfig';
import {Issue} from '../../core/types/issue';

export interface GalleryState {
  collectionConfig: CollectionConfig;
  issueData: Issue[];
  galleryLoading: boolean;
  issueLoading: boolean;
}

export const initialState: GalleryState = {
  collectionConfig: null,
  issueData: [],
  galleryLoading: false,
  issueLoading: false
};

export function galleryReducer(state = initialState, action: GalleryActions): GalleryState {
  switch (action.type) {
    case GalleryActionTypes.GetCollectionConfig:
      return {
        ...state,
        galleryLoading: true
      };

    case GalleryActionTypes.GetCollectionConfigSuccess:
      return {
        ...state,
        collectionConfig: action.payload,
        galleryLoading: false
      };

    case GalleryActionTypes.GetCollectionConfigFailure:
      return {
        ...state,
        galleryLoading: false
      };

    case GalleryActionTypes.GetIssueData:
      return {
        ...state,
        issueLoading: true
      };

    case GalleryActionTypes.GetIssueDataSuccess:
      return {
        ...state,
        issueData: action.payload,
        issueLoading: false
      };

    case GalleryActionTypes.GetIssueDataFailure:
      return {
        ...state,
        issueLoading: false
      };

    default:
      return state;
  }
}
