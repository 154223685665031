import { Component, Input, DoCheck } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { appConfig } from '../../core/config/app.config';
import { Language } from '../../core/config/config.model';
import { Globals } from '../../core/globals';

@Component({
    selector: 'app-home-layout',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements DoCheck {
    private languages: Language[];
    private current_ui_lanuage = '';
    constructor(public translate: TranslateService,
                private router: Router,
                private globals: Globals
    ) {
        this.languages = appConfig.languageList;
        for (const language of this.languages) {
            translate.addLangs([language.value]);
        }

        translate.use(this.globals.USER_DESIRED_UI_LANGUAGE);
    }

    ngDoCheck(): void {
        if (this.current_ui_lanuage !== this.globals.USER_DESIRED_UI_LANGUAGE) {
            this.translate.use(this.globals.USER_DESIRED_UI_LANGUAGE);
            this.current_ui_lanuage = this.globals.USER_DESIRED_UI_LANGUAGE;
        }
    }

    enterCollections(): void {
        this.router.navigate(['/gallery']);
    }
}
