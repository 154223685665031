import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  ReadFile, ToggleGridBlockSelection,
  SetImageNaturalSize,
  SetImageScale,
  ToggleGrid,
  GetPageData, ToggleArticleSelection, ToggleShowAllArticles, ImageLoadStart,
  GetNextPage, GetPrevPage,
  ToggleTranslationMode,
  GetCollectionConfig
} from '../display.actions';
import {
  AppStateWithDisplay,
  getOverlayConfig,
  getOverlayScaledImageSize,
  getGridBlocks,
  getOverlayScale,
  getShowGrid,
  getSelectedGridBlockId,
  getSelectedGridBlockAbsCoordinates,
  getPageId,
  getUnscaledImageSize,
  getSelectedArticleId,
  getShowAllArticles,
  getImageLoading,
  getShowTranslation,
  getCollectionConfig
} from '../display.selectors';
import { Observable, Subscription } from 'rxjs';
import { Overlay, Rect, Size } from '../../../core/types/overlay';
import { GridBlock } from '../../../core/types/grid';
import { CollectionConfig } from '../../../core/types/collectionConfig';
import { Globals } from '../../../core/globals';
import { MenuService } from '../../../core/service/menu.service';

@Component({
  selector: 'app-display-layout',
  templateUrl: './display-layout.component.html',
  styleUrls: ['./display-layout.component.scss']
})
export class DisplayLayoutComponent implements OnInit, OnDestroy {
  overlayConfig$: Observable<Overlay>;
  imageSize$: Observable<Size>;
  unscaledImageSize$: Observable<Size>;
  grid$: Observable<GridBlock[]>;
  scale$: Observable<number>;
  showGrid$: Observable<boolean>;
  selectedGridBlockCoordinates$: Observable<Rect>;
  selectedGridBlockId$: Observable<string>;
  pageId$: Observable<number>;
  selectedArticleId$: Observable<number>;
  showAllArticles$: Observable<boolean>;
  showTranslation$: Observable<boolean>;
  imageLoading$: Observable<boolean>;
  collectionConfig$: Observable<CollectionConfig>;
  pageUrl = '';
  subscription: Subscription = new Subscription();

  constructor(
    private store: Store<AppStateWithDisplay>,
    private menuService: MenuService,
    private globals: Globals
  ) {
    this.overlayConfig$ = store.pipe(select(getOverlayConfig));
    this.imageSize$ = store.pipe(select(getOverlayScaledImageSize));
    this.grid$ = store.pipe(select(getGridBlocks));
    this.scale$ = store.pipe(select(getOverlayScale));
    this.showGrid$ = store.pipe(select(getShowGrid));
    this.selectedGridBlockId$ = store.pipe(select(getSelectedGridBlockId));
    this.selectedGridBlockCoordinates$ = store.pipe(select(getSelectedGridBlockAbsCoordinates));
    this.pageId$ = store.pipe(select(getPageId));
    this.unscaledImageSize$ = store.pipe(select(getUnscaledImageSize));
    this.selectedArticleId$ = store.pipe(select(getSelectedArticleId));
    this.showAllArticles$ = store.pipe(select(getShowAllArticles));
    this.showTranslation$ = store.pipe(select(getShowTranslation));
    this.imageLoading$ = store.pipe(select(getImageLoading));
    this.collectionConfig$ = store.pipe(select(getCollectionConfig));

    this.subscription.add(menuService.menuLoadSingleIssue$.subscribe((pageUrl: string) => {
      this.getPageData(pageUrl);
    }));
    this.subscription.add(menuService.menuToggleTranslationMode$.subscribe(() => {
      this.toggleTranslationMode();
    }));
    this.subscription.add(menuService.menuToggleShowAllTranslation$.subscribe(() => {
      this.toggleShowAllArticles();
    }));
    this.subscription.add(menuService.menuToggleGrid$.subscribe(() => {
      this.toggleShowGrid();
    }));
    this.subscription.add(menuService.menuUploadJSON$.subscribe((file: File) => {
      this.fileSelected(file);
    }));
    this.subscription.add(menuService.menuChangeZoomLevel$.subscribe((scale: number) => {
      this.scaleChanged(scale);
    }));
  }

  imageLoad(size: Size) {
    this.store.dispatch(new SetImageNaturalSize(size));
  }

  imageLoadStart() {
    this.store.dispatch(new ImageLoadStart());
  }

  ngOnInit() {
    this.openMainGallery();
    this.getPageData(this.globals.pageUrl);
  }

  fileSelected(file: File) {
    this.store.dispatch(new ReadFile(file));
  }

  scaleChanged(scale: number) {
    this.store.dispatch(new SetImageScale(scale));
  }

  toggleGridBlockSelection(blockId: string) {
    this.store.dispatch(new ToggleGridBlockSelection(blockId));
  }

  toggleShowGrid() {
    this.store.dispatch(new ToggleGrid());
  }

  toggleArticle(articleId: number) {
    this.store.dispatch(new ToggleArticleSelection(articleId));
  }

  toggleShowAllArticles() {
    this.store.dispatch(new ToggleShowAllArticles());
  }

  toggleTranslationMode() {
    this.store.dispatch(new ToggleTranslationMode());
  }

  openMainGallery() {
    // this.store.dispatch(new openMainGallery());
    this.store.dispatch(new GetCollectionConfig('correio-de-africa'));
  }

  getPageData(url: string) {
    this.store.dispatch(new GetPageData(url));
  }

  movePage(pageTo: string, overlayConfig: Overlay) {
    if (pageTo === 'next') {
      this.store.dispatch(new GetNextPage(overlayConfig));
    } else {
      this.store.dispatch(new GetPrevPage(overlayConfig));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
