import { Component, Input, OnInit } from '@angular/core';
import { Rect } from '../../../core/types/overlay';

@Component({
  selector: 'app-grid-nested-block',
  templateUrl: './grid-nested-block.component.html',
  styleUrls: ['./grid-nested-block.component.scss']
})
export class GridNestedBlockComponent implements OnInit {
  @Input() rect: Rect;
  @Input() id: string;

  constructor() { }

  ngOnInit() {
  }

}
