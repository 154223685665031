import { Action } from '@ngrx/store';
import { Overlay, Size } from '../../core/types/overlay';
import { CollectionConfig } from '../../core/types/collectionConfig';

export enum DisplayActionTypes {
  ReadFile = '[Display] Read file',
  ReadFileSuccess = '[Display] Read file success',
  GetPageData = '[Display] Get Page Data',
  GetPageDataSuccess = '[Display] Get Page Data success',
  GetPageDataFailure = '[Display] Get Page Data failure',
  GetNextPage = '[Display] Get next page',
  GetNextPageSuccess = '[Display] Get next page success',
  GetNextPageFailure = '[Display] Get next page failure',
  GetPrevPage = '[Display] Get previous page',
  GetPrevPageSuccess = '[Display] Get previous page success',
  GetPrevPageFailure = '[Display] Get previous page failure',
  SetImageNaturalSize = '[Display] Set image natural size',
  SetScale = '[Display] Set scale',
  ToggleGrid = '[Display] Toggle grid',
  ToggleGridBlockSelection = '[Display] Toggle grid block selection',
  ToggleShowAllArticles = '[Display] Toggle show all articles',
  ToggleArticleSelection = '[Display] Toggle article selection',
  ToggleTranslationMode = '[Display] Toggle translation mode',
  GetCollectionConfig = '[Display] Get collection config',
  GetCollectionConfigSuccess = '[Display] Get collection config success',
  GetCollectionConfigFailure = '[Display] Get collection config failure',
  ImageLoadStart = '[Display] Image load start'
}

export class ReadFile implements Action {
  readonly type = DisplayActionTypes.ReadFile;
  constructor(public payload: File) {}
}

export class ReadFileSuccess implements Action {
  readonly type = DisplayActionTypes.ReadFileSuccess;
  constructor(public payload: Overlay) {}
}

export class GetPageData implements Action {
  readonly type = DisplayActionTypes.GetPageData;
  constructor(public payload: string) {}
}

export class GetPageDataSuccess implements Action {
  readonly type = DisplayActionTypes.GetPageDataSuccess;
  constructor(public payload: Overlay) {}
}

export class GetPageDataFailure implements Action {
  readonly type = DisplayActionTypes.GetPageDataFailure;
}

export class GetNextPage implements Action {
  readonly type = DisplayActionTypes.GetNextPage;
  constructor(public payload: Overlay) {}
}

export class GetNextPageSuccess implements Action {
    readonly type = DisplayActionTypes.GetNextPageSuccess;
    constructor(public payload: Overlay) {}
}

export class GetNextPageFailure implements Action {
    readonly type = DisplayActionTypes.GetNextPageFailure;
}

export class GetPrevPage implements  Action {
  readonly type = DisplayActionTypes.GetPrevPage;
  constructor(public payload: Overlay) {}
}

export class GetPrevPageSuccess implements Action {
    readonly type = DisplayActionTypes.GetPrevPageSuccess;
    constructor(public payload: Overlay) {}
}

export class GetPrevPageFailure implements Action {
    readonly type = DisplayActionTypes.GetPrevPageFailure;
}

export class SetImageNaturalSize implements Action {
  readonly type = DisplayActionTypes.SetImageNaturalSize;
  constructor(public payload: Size) {}
}

export class SetImageScale implements Action {
  readonly type = DisplayActionTypes.SetScale;
  constructor(public payload: number) {}
}

export class ToggleGrid implements Action {
  readonly type = DisplayActionTypes.ToggleGrid;
}

export class ToggleGridBlockSelection implements Action {
  readonly type = DisplayActionTypes.ToggleGridBlockSelection;
  constructor(public payload: string) {}
}

export class ToggleShowAllArticles implements Action {
  readonly type = DisplayActionTypes.ToggleShowAllArticles;
}

export class ToggleArticleSelection implements Action {
  readonly type = DisplayActionTypes.ToggleArticleSelection;
  constructor(public payload: number) {}
}

export class ImageLoadStart implements Action {
  readonly type = DisplayActionTypes.ImageLoadStart;
}

export class ToggleTranslationMode implements Action {
  readonly type = DisplayActionTypes.ToggleTranslationMode;
}

export class GetCollectionConfig implements Action {
  readonly type = DisplayActionTypes.GetCollectionConfig;
  constructor(public payload: string) {}
}

export class GetCollectionConfigSuccess implements Action {
  readonly type = DisplayActionTypes.GetCollectionConfigSuccess;
  constructor(public payload: CollectionConfig) {}
}

export class GetCollectionConfigFailure implements Action {
  readonly type = DisplayActionTypes.GetCollectionConfigFailure;
}

export type DisplayActions = ReadFile
  | ReadFileSuccess
  | GetPageData
  | GetPageDataSuccess
  | GetNextPage
  | GetNextPageSuccess
  | GetNextPageFailure
  | GetPrevPage
  | GetPrevPageSuccess
  | GetPrevPageFailure
  | SetImageNaturalSize
  | SetImageScale
  | ToggleGrid
  | ToggleGridBlockSelection
  | ToggleShowAllArticles
  | ToggleArticleSelection
  | ToggleTranslationMode
  | GetCollectionConfig
  | GetCollectionConfigSuccess
  | GetCollectionConfigFailure
  | ImageLoadStart;
