import { Action } from '@ngrx/store';
import { CollectionConfig } from '../../core/types/collectionConfig';
import { Issue } from '../../core/types/issue';

export enum GalleryActionTypes {
  GetCollectionConfig = '[Gallery] Get collection config',
  GetCollectionConfigSuccess = '[Gallery] Get collection config success',
  GetCollectionConfigFailure = '[Gallery] Get collection config failure',
  GetIssueData = '[Gallery] Get issue data',
  GetIssueDataSuccess = '[Gallery] Get issue data success',
  GetIssueDataFailure = '[Gallery] Get issue data failure '
}

export class GetCollectionConfig implements Action {
  readonly type = GalleryActionTypes.GetCollectionConfig;
  constructor (public payload: string) {}
}

export class GetCollectionConfigSuccess implements Action {
  readonly type = GalleryActionTypes.GetCollectionConfigSuccess;
  constructor (public payload: CollectionConfig) {}
}

export class GetCollectionConfigFailure implements Action {
  readonly type = GalleryActionTypes.GetCollectionConfigFailure;
}

export class GetIssueData implements Action {
  readonly type = GalleryActionTypes.GetIssueData;
  constructor (public payload: string[]) {}
}

export class GetIssueDataSuccess implements Action {
  readonly type = GalleryActionTypes.GetIssueDataSuccess;
  constructor (public payload: Issue[]) {}
}

export class GetIssueDataFailure implements Action {
  readonly type = GalleryActionTypes.GetIssueDataFailure;
}

export type GalleryActions = GetCollectionConfig
  | GetCollectionConfigSuccess
  | GetCollectionConfigFailure
  | GetIssueData
  | GetIssueDataSuccess
  | GetIssueDataFailure;
