import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { appConfig } from './core/config/app.config';
import { Globals } from './core/globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private pageRouter = '/';
  constructor(private titleService: Title,
              private router: Router,
              private globals: Globals) {
    this.router.events.subscribe((val) => {
      this.pageRouter = this.router.url.substring(1);
    });
    globals.USER_DESIRED_UI_LANGUAGE = appConfig.appLanguage;
  }
  ngOnInit() {
    this.titleService.setTitle(appConfig.appTitle);
  }
}
