import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  GetCollectionConfig, GetIssueData
} from '../gallery.actions';
import {
  AppStateWithGallery,
  getCollectionConfig,
  getIssueData
} from '../gallery.selectors';
import { Observable } from 'rxjs';
import { CollectionConfig } from '../../../core/types/collectionConfig';
import { Issue } from '../../../core/types/issue';

@Component({
  selector: 'app-gallery-layout',
  templateUrl: './gallery-layout.component.html',
  styleUrls: ['./gallery-layout.component.scss']
})
export class GalleryLayoutComponent implements OnInit {
  collectionConfig$: Observable<CollectionConfig>;
  issueData$: Observable<Issue[]>;

  constructor(private store: Store<AppStateWithGallery>) {
    this.collectionConfig$ = store.pipe(select(getCollectionConfig));
    this.issueData$ = store.pipe(select(getIssueData));
  }

  ngOnInit() {
    this.getCollectionData();
  }

  getCollectionData() {
    this.store.dispatch(new GetCollectionConfig('correio-de-africa'));
  }

  getIssueData(urls: string[]) {
    this.store.dispatch(new GetIssueData(urls));
  }
}
