import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Overlay, Position, Rect, Size } from '../../../core/types/overlay';
import { GridBlock } from '../../../core/types/grid';
import * as _ from 'lodash';
import { calculateRect } from '../../../core/helpers';
import { CollectionConfig } from '../../../core/types/collectionConfig';

const roundingDigits = 1;

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, OnDestroy {
  @Input() overlay: Overlay;
  @Input() imageSize: Size;
  @Input() unscaledImageSize: Size;
  @Input() grid: GridBlock[];
  @Input() showGrid: boolean;
  @Input() selectedGridBlockId: string;
  @Input() pageId: string;
  @Input() selectedGridBlockCoordinates: Rect;
  @Input() scale: number;
  @Input() selectedArticleId: number;
  @Input() showAllArticles: boolean;
  @Input() showTranslation: boolean;
  @Input() collectionConfig: CollectionConfig;
  @Input() imageLoading: boolean;
  @Output() imageLoad = new EventEmitter<Size>();
  @Output() toggleGridBlockSelection = new EventEmitter<string>();
  @Output() toggleArticleSelection = new EventEmitter<number>();
  @Output() imageLoadStart = new EventEmitter<void>();

  selectionInProgress = false;
  selectionRect: Rect;

  @ViewChild('canvas')
  private canvas: ElementRef;
  private selectionStart: Position;
  private moveListener = this.mouseMove.bind(this);

  constructor() { }

  articleSelection(articleId: number) {
    this.toggleArticleSelection.emit(articleId);
  }

  gridBlockSelection(blockId: string) {
    this.toggleGridBlockSelection.emit(blockId);
  }

  mouseDown(event: MouseEvent) {
    // Only allow selection when the grid overlay is displayed.
    // Selecting when text overlay is on breaks the selection because
    // text overlay uses absolute pixels and scales via CSS transform: scale().
    if (!this.showGrid) {
      return;
    }
    this.selectionStart = this.getRelativeClickCoordinates(event);
    this.canvas.nativeElement.addEventListener('mousemove', this.moveListener);
  }

  mouseUp(event: MouseEvent) {
    this.removeMouseMoveListener();
    if (!this.selectionInProgress) {
      return;
    }
    this.selectionInProgress = false;
    const endCoordinates = this.getRelativeClickCoordinates(event);

    let selectedRect;
    if (this.selectedGridBlockId) {
      // If an article block is selected, calculate the selection box
      // coordinates relative to the selected article block.
      const start = new Position(
        this.selectionStart.x - this.selectedGridBlockCoordinates.x,
        this.selectionStart.y - this.selectedGridBlockCoordinates.y
      );
      const end = new Position(
        endCoordinates.x - this.selectedGridBlockCoordinates.x,
        endCoordinates.y - this.selectedGridBlockCoordinates.y
      );

      const gridBlockAbsoluteSize = new Size(
        this.selectedGridBlockCoordinates.width,
        this.selectedGridBlockCoordinates.height
      );
      selectedRect = calculateRect(start, end, gridBlockAbsoluteSize);
    } else {
      // If no article block is selected, calculate the selection box
      // relative to the canvas.
      selectedRect = calculateRect(this.selectionStart, endCoordinates, this.imageSize);
    }

    const roundedValues = new Rect(
      _.round(selectedRect.x, roundingDigits),
      _.round(selectedRect.y, roundingDigits),
      _.round(selectedRect.width, roundingDigits),
      _.round(selectedRect.height, roundingDigits)
    );
    console.log('"x": ' + `${roundedValues.x}` +
      ', "y": ' + `${roundedValues.y}` +
      ', "width": ' + `${roundedValues.width}` +
      ', "height": ' + `${roundedValues.height}`);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.removeMouseMoveListener();
  }

  viewerImageLoaded(size: Size) {
    this.imageLoad.emit(size);
  }

  viewerImageLoadStarted() {
    this.imageLoadStart.emit();
  }

  private mouseMove(event: MouseEvent) {
    if (!this.selectionInProgress) {
      this.selectionInProgress = true;
    }
    const currentPosition = this.getRelativeClickCoordinates(event);
    this.selectionRect = calculateRect(
      this.selectionStart,
      currentPosition,
      this.imageSize
    );
  }

  private removeMouseMoveListener() {
    this.canvas.nativeElement.removeEventListener('mousemove', this.moveListener);
  }

  private getRelativeCoordinates(position: Position, target: EventTarget): Position {
    if (target === this.canvas.nativeElement) {
      return position;
    }
    if (target instanceof HTMLElement) {
      if (!target.offsetParent) {
        return;
      }
      return this.getRelativeCoordinates(
        new Position(position.x + target.offsetLeft, position.y + target.offsetTop),
        target.offsetParent
      );
    }
  }

  // Get the mouse coordinates relative to canvas div's top left.
  private getRelativeClickCoordinates(event: MouseEvent): Position {
    return this.getRelativeCoordinates(new Position(event.offsetX, event.offsetY), event.target);
  }
}
