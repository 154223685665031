export class Position {
  constructor(
    public x: number,
    public y: number
  ) {}
}

export class Size {
  constructor(
    public width: number,
    public height: number
  ) {}
}

export class Rect {
  constructor(
    public x: number,
    public y: number,
    public width: number,
    public height: number
  ) {}
}

export class BlockHtml {
  constructor(
    public html: string,
    public css: string = '',
    public annotation_circle?: string,
    public annotation: string = ''
  ) {}
}

// Rect values are % relative to the containing article block.
export class OverlayBlock {
  constructor(
    public id: string,
    public rect: Rect
  ) {}
}

// Rect values are % relative to the canvas width and height.
export interface OverlayArticle {
  id: number;
  rect: Rect;
  en_US?: BlockHtml;
  blocks?: OverlayBlock[];
  source?: string;
  pt_PT?: BlockHtml;
  ru_RU?: BlockHtml;
}

export interface Overlay {
  id: number;
  pageName: string;
  imageUrl: string;
  pageCss: string;
  articles: OverlayArticle[];
}
