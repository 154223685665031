import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Overlay, Size } from '../../../core/types/overlay';
import { CollectionConfig } from '../../../core/types/collectionConfig';

@Component({
  selector: 'app-text-overlay',
  templateUrl: './text-overlay.component.html',
  styleUrls: ['./text-overlay.component.scss']
})
export class TextOverlayComponent implements OnInit {
  @Input() originalSize: Size;
  @Input() overlay: Overlay;
  @Input() collectionConfig: CollectionConfig;
  @Input() scale: number;
  @Input() selectedArticleId: string;
  @Input() showAllArticles: boolean;
  @Input() showTranslation: boolean;
  @Output() toggleArticleSelection = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  toggleArticle(articleId: number) {
    this.toggleArticleSelection.emit(articleId);
  }
}
