import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DisplayLayoutComponent } from './display-layout/display-layout.component';

const routes: Routes = [
  {path: 'display', component: DisplayLayoutComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisplayRoutingModule {}
