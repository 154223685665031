import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { appConfig } from '../../../core/config/app.config';
import { PageRef } from '../../../core/types/issue';
import { Globals } from '../../../core/globals';

@Component({
  selector: 'app-page-block',
  templateUrl: './page-block.component.html',
  styleUrls: ['./page-block.component.scss']
})
export class PageBlockComponent implements OnInit {
  @Input() page: PageRef;
  @Input() pageCount: number;
  @Input() pageIndex: number;
  @Input() issueDate: string;
  @Output() displayPage = new EventEmitter<string>();

  isHovering = false;
  issueColor = null;

  constructor(
    private router: Router,
    private globals: Globals
  ) {
  //  this.issueColor = appConfig.appColorForIssues;
  }

  ngOnInit() {
  }

  gotoPage() {
    this.globals.pageUrl = this.page.pageUrl;
    this.router.navigate(['/display']);
  }

  mouseHovering() {
    this.isHovering = true;
  }

  mouseLeft() {
    this.isHovering = false;
  }

}
