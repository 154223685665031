import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Size } from '../../../core/types/overlay';

@Component({
  selector: 'app-viewer-image',
  templateUrl: './viewer-image.component.html',
  styleUrls: ['./viewer-image.component.scss']
})
export class ViewerImageComponent implements OnInit, OnChanges {
  @Input() imageUrl: string;
  @Input() @HostBinding('style.width') width: string;
  @Input() @HostBinding('style.height') height: string;
  @Output() loadStarted = new EventEmitter<void>();
  @Output() load = new EventEmitter<Size>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageUrl) {
      setTimeout(() => this.loadStarted.emit());
    }
  }

  ngOnInit() {
  }

  imageLoad(event) {
    const {naturalWidth, naturalHeight} = event.target;
    // Must wrap in timeout to make sure it fires after loadStarted event when the image is cached.
    setTimeout(() => this.load.emit(new Size(naturalWidth, naturalHeight)));
  }
}
