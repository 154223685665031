import { Component, OnInit, Input } from '@angular/core';
import { Issue } from '../../../core/types/issue';

@Component({
  selector: 'app-viewer-issue',
  templateUrl: './viewer-issue.component.html',
  styleUrls: ['./viewer-issue.component.scss']
})
export class ViewerIssueComponent implements OnInit {
  @Input() issue: Issue;

  constructor() { }

  ngOnInit() {
  }

}
