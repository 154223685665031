// These styles are applied to each dynamically compiled text block contents.
const padding = '10px';
export const textBlockBaseStyle = `
  :host {
    display: block;
    background-color: white;
    min-height: calc(100% + (${padding} * 2));
    width: calc(100% + (${padding} * 2));
    position: relative;
    top: -${padding};
    left: -${padding};
    padding: ${padding};
    box-shadow: 2px 2px 6px 2px gray;
  }`;

