import {
  AfterViewInit,
  Compiler,
  Component,
  ComponentFactoryResolver, EventEmitter,
  Input, NgModule,
  Output,
  ViewChild,
  ViewContainerRef,
  HostListener,
  DoCheck
} from '@angular/core';
import * as _ from 'lodash';
import { OverlayArticle } from '../../../core/types/overlay';
import { textBlockBaseStyle } from './text-block-style';
import { Globals } from '../../../core/globals';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss']
})
export class TextBlockComponent implements DoCheck, AfterViewInit {
  @Input() article: OverlayArticle;
  @Input() dcmiLanguage: string;
  @Input() collectionCss: string;
  @Input() showAllArticles: boolean;
  @Input() showTranslation: boolean;
  @Input() selectedArticleId: number;
  @Input() globalStyles: string;
  @Output() toggleSelection = new EventEmitter<number>();
  @ViewChild('textContainer', {read: ViewContainerRef}) textContainer: ViewContainerRef;
  @ViewChild('originContainer', {read: ViewContainerRef}) originContainer: ViewContainerRef;

  userDesiredTargetLanguage = '';

  constructor(
    private resolver: ComponentFactoryResolver,
    private compiler: Compiler,
    private globals: Globals
  ) {
    this.userDesiredTargetLanguage = globals.USER_DESIRED_TARGET_LANGUAGE;
  }

  ngAfterViewInit() {
    this.textContainer.clear();
    const articleSrcLang = this.article.source ? this.article.source : this.dcmiLanguage;
    let targetLanguage = this.globals.USER_DESIRED_TARGET_LANGUAGE;
    if ( articleSrcLang === targetLanguage ) {
      targetLanguage = this.dcmiLanguage;
    }
    let component;
    this.compiler.clearCache();
    if (targetLanguage === 'en-US' && this.article.en_US) {
      component = Component({
        template: this.article.en_US.html + this.article.en_US.annotation,
        styles: this.article.en_US.css
          ? [textBlockBaseStyle, this.collectionCss, this.globalStyles || '', this.article.en_US.css]
          : [textBlockBaseStyle, this.collectionCss, this.globalStyles || '']
      })(class {});
    } else if (targetLanguage === 'pt-PT' && this.article.pt_PT) {
      component = Component({
        template: this.article.pt_PT.html + this.article.pt_PT.annotation,
        styles: this.article.pt_PT.css
          ? [textBlockBaseStyle, this.collectionCss, this.globalStyles || '', this.article.pt_PT.css]
          : [textBlockBaseStyle, this.collectionCss, this.globalStyles || '']
      })(class {});
    } else if (targetLanguage === 'ru-RU' && this.article.ru_RU) {
      component = Component({
        template: this.article.ru_RU.html + this.article.ru_RU.annotation,
        styles: this.article.ru_RU.css
            ? [textBlockBaseStyle, this.collectionCss, this.globalStyles || '', this.article.ru_RU.css]
            : [textBlockBaseStyle, this.collectionCss, this.globalStyles || '']
      })(class {});
    } else {
      component = Component({
        template: '',
        styles: [textBlockBaseStyle, this.collectionCss, this.globalStyles || '']
      })(class {});
    }
    const module = NgModule({
      declarations: [component]
    })(class {});

    this.compiler.compileModuleAndAllComponentsAsync(module)
      .then(factories => {
        const factory = factories.componentFactories[0];
        this.textContainer.createComponent(factory);
      });

    this.compiler.clearCache();
    const originAnnotationStr = this.article.pt_PT.annotation_circle;
    const annotationStrArray = _.split(originAnnotationStr, '></a>');
    let annotationCircle = '';
    for (const annotationStr of annotationStrArray) {
      if (annotationStr.length === 0) {
        continue;
      }
      let dotx = -1;
      let doty = -1;
      let annotationStrBuf = annotationStr;
      const dotxStr = annotationStr.match('dotx=\'(.*)\' doty');
      if (dotxStr) {
        dotx = parseFloat(dotxStr[1]);
      }
      const dotyStrBuf = annotationStr.match('doty=([\'])(?:(?=(\\\\?))\\2.)*?\\1');
      if (dotyStrBuf) {
        const dotyStr = dotyStrBuf[0].match('doty=\'(.*)\'')[1];
        doty = parseFloat(dotyStr);
      }
      if (dotx >= 0 && doty >= 0) {
        annotationStrBuf += ` style='top: ${doty}%; left: ${dotx}%;'></a>`;
      } else {
        annotationStrBuf += '></a>';
      }
      annotationCircle += annotationStrBuf;
    }
    const originAnnotationComponent = Component({
      template: annotationCircle + this.article.pt_PT.annotation
    })(class  {});

    const originAnnotationModule = NgModule({
      declarations: [originAnnotationComponent]
    })(class  {});

    this.compiler.compileModuleAndAllComponentsAsync(originAnnotationModule)
      .then(factories => {
        const factory = factories.componentFactories[0];
        this.originContainer.createComponent(factory);
      });
  }

  ngDoCheck(): void {
    if (this.userDesiredTargetLanguage !== this.globals.USER_DESIRED_TARGET_LANGUAGE) {
      this.textContainer.clear();
      const articleSrcLang = this.article.source ? this.article.source : this.dcmiLanguage;
      let targetLanguage = this.globals.USER_DESIRED_TARGET_LANGUAGE;
      if ( articleSrcLang === targetLanguage ) {
        targetLanguage = this.dcmiLanguage;
      }
      let component;
      this.compiler.clearCache();
      if (targetLanguage === 'en-US' && this.article.en_US) {
        component = Component({
          template: this.article.en_US.html + this.article.en_US.annotation,
          styles: this.article.en_US.css
              ? [textBlockBaseStyle, this.collectionCss, this.globalStyles || '', this.article.en_US.css]
              : [textBlockBaseStyle, this.collectionCss, this.globalStyles || '']
        })(class {});
      } else if (targetLanguage === 'pt-PT' && this.article.pt_PT) {
        component = Component({
          template: this.article.pt_PT.html + this.article.pt_PT.annotation,
          styles: this.article.pt_PT.css
              ? [textBlockBaseStyle, this.collectionCss, this.globalStyles || '', this.article.pt_PT.css]
              : [textBlockBaseStyle, this.collectionCss, this.globalStyles || '']
        })(class {});
      } else if (targetLanguage === 'ru-RU' && this.article.ru_RU) {
        component = Component({
          template: this.article.ru_RU.html + this.article.ru_RU.annotation,
          styles: this.article.ru_RU.css
              ? [textBlockBaseStyle, this.collectionCss, this.globalStyles || '', this.article.ru_RU.css]
              : [textBlockBaseStyle, this.collectionCss, this.globalStyles || '']
        })(class {});
      } else {
        component = Component({
          template: '',
          styles: [textBlockBaseStyle, this.collectionCss, this.globalStyles || '']
        })(class {});
      }
      const module = NgModule({
        declarations: [component]
      })(class {});

      this.compiler.compileModuleAndAllComponentsAsync(module)
          .then(factories => {
            const factory = factories.componentFactories[0];
            this.textContainer.createComponent(factory);
          });

      this.userDesiredTargetLanguage = this.globals.USER_DESIRED_TARGET_LANGUAGE;
    }
  }

  toggleArticleSelection(event) {
    let isAnnotationLink = false;
    for (const pathElement of event.path) {
      if (pathElement.attributes && pathElement.attributes.hasOwnProperty('data-featherlight')) {
        isAnnotationLink = true;
        break;
      }
    }
    if (!isAnnotationLink && this.showTranslation) {
      this.toggleSelection.emit(this.article.id);
    }
  }
}
