import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { appConfig } from '../config/app.config';

@Injectable()
export class MenuService {
    private menuLoadSingleIssueSource = new Subject<string>();
    private menuToggleTranslationModeSource = new Subject();
    private menuToggleShowAllTranslationSource = new Subject();
    private menuToggleGridSource = new Subject();
    private menuUploadJSONSource = new Subject<File>();
    private menuChangeZoomLevelSource = new Subject<number>();

    menuLoadSingleIssue$ = this.menuLoadSingleIssueSource.asObservable();
    menuToggleTranslationMode$ = this.menuToggleTranslationModeSource.asObservable();
    menuToggleShowAllTranslation$ = this.menuToggleShowAllTranslationSource.asObservable();
    menuToggleGrid$ = this.menuToggleGridSource.asObservable();
    menuUploadJSON$ = this.menuUploadJSONSource.asObservable();
    menuChangeZoomLevel$ = this.menuChangeZoomLevelSource.asObservable();

    menuLoadSingleIssue(pageUrl: string) {
        this.menuLoadSingleIssueSource.next(pageUrl);
    }

    toggleTranslationMode() {
        this.menuToggleTranslationModeSource.next();
    }

    toggleShowAllTranslation() {
        this.menuToggleShowAllTranslationSource.next();
    }

    toggleGrid() {
        this.menuToggleGridSource.next();
    }

    uploadJSON(file: File) {
        this.menuUploadJSONSource.next(file);
    }

    changeMenuZoomLevel(scale: number) {
        this.menuChangeZoomLevelSource.next(scale);
    }
}
