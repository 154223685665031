import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { displayReducer } from './display.reducer';
import { DisplayLayoutComponent } from './display-layout/display-layout.component';
import { DisplayRoutingModule } from './display-routing.module';
import { MaterialModule } from '../../material.module';
import { EffectsModule } from '@ngrx/effects';
import { DisplayEffects } from './display.effects';
import { ViewerComponent } from './viewer/viewer.component';
import { ViewerImageComponent } from './viewer-image/viewer-image.component';
import { ViewerGridComponent } from './viewer-grid/viewer-grid.component';
import { GridBlockComponent } from './grid-block/grid-block.component';
import { GridNestedBlockComponent } from './grid-nested-block/grid-nested-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewerSelectionComponent } from './viewer-selection/viewer-selection.component';
import { TextOverlayComponent } from './text-overlay/text-overlay.component';
import { TextBlockComponent } from './text-block/text-block.component';
import { SharedModule } from '../shared/shared.module';
import { Globals } from '../../core/globals';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    DisplayLayoutComponent,
    ViewerComponent,
    ViewerImageComponent,
    ViewerGridComponent,
    GridBlockComponent,
    GridNestedBlockComponent,
    ViewerSelectionComponent,
    TextOverlayComponent,
    TextBlockComponent
  ],
  imports: [
    CommonModule,
    DisplayRoutingModule,
    StoreModule.forFeature('display', displayReducer),
    MaterialModule,
    EffectsModule.forFeature([DisplayEffects]),
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [Globals]
})
export class DisplayModule { }
