import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  DisplayActionTypes,
  GetPageData,
  GetPageDataSuccess,
  GetPageDataFailure,
  GetNextPage,
  GetNextPageSuccess,
  GetNextPageFailure,
  GetPrevPage,
  GetPrevPageSuccess,
  GetPrevPageFailure,
  ReadFile,
  ReadFileSuccess,
  GetCollectionConfig,
  GetCollectionConfigSuccess,
  GetCollectionConfigFailure
} from './display.actions';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { EMPTY, fromEvent, Observable, of } from 'rxjs';
import { Overlay } from '../../core/types/overlay';
import { CollectionConfig } from '../../core/types/collectionConfig';
import { PageDataService, CollectionDataService } from '../../core/service/getjsondata';

@Injectable()
export class DisplayEffects {
  @Effect()
  readFile$: Observable<Action> = this.actions$.pipe(
    ofType(DisplayActionTypes.ReadFile),
    switchMap((action: ReadFile) => {
      const reader = new FileReader();
      const eventObservable = fromEvent(reader, 'loadend')
        .pipe(
          switchMap((e: any) => {
            const json = JSON.parse(e.target.result);
            return of(new ReadFileSuccess(json));
          }),
          catchError(() => {
            console.log('Error');
            return EMPTY;
          })
        );
      reader.readAsText(action.payload);
      return eventObservable;
    })
  );

  @Effect()
  getPage$: Observable<Action> = this.actions$.pipe(
    ofType(DisplayActionTypes.GetPageData),
    switchMap((action: GetPageData) => this.http.get(action.payload).pipe(
      map(x => {
        const pageData: Overlay = x as Overlay;
        return new GetPageDataSuccess(pageData);
      }),
      catchError(error => of(new GetPageDataFailure()))
    ))
  );

  @Effect()
  getNextPage$: Observable<Action> = this.actions$.pipe(
    ofType(DisplayActionTypes.GetNextPage),
    switchMap((action: GetNextPage) => this.pageDataService.getNextPage().pipe(
      map(x => new GetNextPageSuccess(x)),
      catchError(error => of(new GetNextPageFailure()))
    ))
  );

  @Effect()
  getPrevPage$: Observable<Action> = this.actions$.pipe(
    ofType(DisplayActionTypes.GetPrevPage),
    switchMap((action: GetPrevPage) => this.pageDataService.getPrevPage().pipe(
      map(x => new GetPrevPageSuccess(x)),
      catchError(error => of(new GetPrevPageFailure()))
    ))
  );

  @Effect()
  readCollectionConfig$: Observable<Action> = this.actions$.pipe(
    ofType(DisplayActionTypes.GetCollectionConfig),
    switchMap( (action: GetCollectionConfig) => this.collectionService.getCollectionConfig().pipe(
      map(x => {
        const collectionConfig: CollectionConfig = x as CollectionConfig;
        return new GetCollectionConfigSuccess(collectionConfig);
      }),
      catchError(error => of(new GetCollectionConfigFailure()))
    ))
  );

  constructor(private http: HttpClient,
              private actions$: Actions,
              private pageDataService: PageDataService,
              private collectionService: CollectionDataService
  ) {}
}
