import { Component, Input, DoCheck } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CollectionConfig } from '../../../core/types/collectionConfig';
import { Globals } from '../../../core/globals';
import { Language } from '../../../core/config/config.model';
import { appConfig } from '../../../core/config/app.config';

@Component({
  selector: 'app-gallery-header',
  templateUrl: './gallery-header.component.html',
  styleUrls: ['./gallery-header.component.scss']
})
export class GalleryHeaderComponent implements DoCheck {
  @Input() collectionConfig: CollectionConfig;

  publicationTitle = '';
  sourceTitle = '';
  issueCount = 0;

  private languages: Language[];

  constructor(
    private translate: TranslateService,
    private globals: Globals
  ) {
    this.languages = appConfig.languageList;
    for (const language of this.languages) {
      translate.addLangs([language.value]);
    }
    translate.use(globals.USER_DESIRED_UI_LANGUAGE);
  }

  ngDoCheck() {
    if (this.collectionConfig) {
      this.publicationTitle = this.collectionConfig.dcmiTitle;
      this.sourceTitle = this.collectionConfig.dcmiSource;
      this.issueCount = this.collectionConfig.issues.length;
    }
  }

}
