import {
  Component,
  OnInit,
  DoCheck,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { CollectionConfig, IssueRef } from '../../../core/types/collectionConfig';
import { Issue } from '../../../core/types/issue';

@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss']
})
export class GalleryViewerComponent implements OnInit, DoCheck {
  @Input() collectionConfig: CollectionConfig;
  @Input() issueData: Issue[];
  @Output() getIssueData = new EventEmitter<string[]>();

  issues: IssueRef[] = [];
  id = 'test';
  oldCollectionConfig: CollectionConfig = null;
  oldIssueData: Issue[] = [];

  constructor() {}

  ngOnInit() {
  }

  ngDoCheck(): void {
    if (this.collectionConfig !== this.oldCollectionConfig) {
      this.issues = this.collectionConfig.issues;
      const issueUrls: string[] = [];
      for (const issue of this.issues) {
        issueUrls.push(issue.issueUrl);
      }
      this.getIssueData.emit(issueUrls);
      this.oldCollectionConfig = this.collectionConfig;
    }
  }

}
