import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EffectsModule } from '@ngrx/effects';
import { GalleryRoutingModule } from './gallery-routing.module';
import { galleryReducer } from './gallery.reducer';
import { GalleryEffects } from './gallery.effects';
import { GalleryHeaderComponent } from './gallery-header/gallery-header.component';
import { GalleryLayoutComponent } from './gallery-layout/gallery-layout.component';
import { Globals } from '../../core/globals';
import { GalleryViewerComponent } from './gallery-viewer/gallery-viewer.component';
import { ViewerIssueComponent } from './viewer-issue/viewer-issue.component';
import { PageBlockComponent } from './page-block/page-block.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    GalleryHeaderComponent,
    GalleryLayoutComponent,
    GalleryViewerComponent,
    ViewerIssueComponent,
    PageBlockComponent
  ],
  imports: [
    CommonModule,
    GalleryRoutingModule,
    StoreModule.forFeature('gallery', galleryReducer),
    EffectsModule.forFeature([GalleryEffects]),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [ Globals ]
})
export class GalleryModule { }
