import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Rect } from '../../../core/types/overlay';
import { GridBlockChild } from '../../../core/types/grid';

@Component({
  selector: 'app-grid-block',
  templateUrl: './grid-block.component.html',
  styleUrls: ['./grid-block.component.scss']
})
export class GridBlockComponent implements OnInit {
  @Input() id: string;
  @Input() rect: Rect;
  @Input() children: GridBlockChild[];
  @Input() selectedGridBlockId: string;
  @Output() toggleGridBlockSelection = new EventEmitter<string>();

  toggleSelection() {
    this.toggleGridBlockSelection.emit(this.id);
  }

  constructor() { }

  ngOnInit() {
  }

}
