import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridBlock } from '../../../core/types/grid';

@Component({
  selector: 'app-viewer-grid',
  templateUrl: './viewer-grid.component.html',
  styleUrls: ['./viewer-grid.component.scss']
})
export class ViewerGridComponent implements OnInit {
  @Input() grid: GridBlock[];
  @Input() selectedGridBlockId: string;
  @Input() pageId: string;
  @Output() toggleGridBlockSelection = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  gridBlockSelectToggle(blockId: string) {
    this.toggleGridBlockSelection.emit(blockId);
  }

}
