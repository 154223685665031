import { Rect } from './overlay';

export class GridBlockChild {
  constructor(
    public id: string,
    public rect: Rect
  ) {}
}

export class GridBlock {
  constructor(
    public id: number,
    public rect: Rect,
    public children: GridBlockChild[] = []
  ) {}
}
