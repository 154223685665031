import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatButtonToggleModule, MatIconModule,
  MatInputModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatMenuModule,
  MatSelectModule,
  MatFormFieldModule
} from '@angular/material';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatFormFieldModule
  ]
})
export class MaterialModule { }
