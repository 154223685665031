import { AppState } from '../../core/app-reducers';
import { DisplayState } from './display.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GridBlock, GridBlockChild } from '../../core/types/grid';
import { Rect, Size } from '../../core/types/overlay';
import * as _ from 'lodash';

export interface AppStateWithDisplay extends AppState {
  display: DisplayState;
}

const getDisplayState = createFeatureSelector<AppStateWithDisplay, DisplayState>('display');

export const getOverlayConfig = createSelector(getDisplayState, state => state.overlayConfig);

export const getOverlayScale = createSelector(getDisplayState, state => state.scale);

export const getGridBlocks = createSelector(
  getDisplayState,
  state => {
    return state.overlayConfig.articles.map(article => {
      const gridBlockChildren = article.blocks && article.blocks
        .map(block => new GridBlockChild(block.id, block.rect));
      return new GridBlock(article.id, article.rect, gridBlockChildren);
    });
  });

export const getOverlayScaledImageSize = createSelector(
  getDisplayState,
  state => {
    return new Size(
      state.imageSize.width * state.scale,
      state.imageSize.height * state.scale
    );
  }
);

export const getUnscaledImageSize = createSelector(
  getDisplayState,
  state => state.imageSize
);

export const getShowGrid = createSelector(getDisplayState, state => state.showGrid);

export const getSelectedGridBlockId = createSelector(getDisplayState, state => state.selectedGridBlockId);

export const getGridBlocksHash = createSelector(
  getGridBlocks,
  (gridBlocks: GridBlock[]) => _.keyBy(gridBlocks, 'id')
);

// Grid block config defines positions and dimensions in percents to container size.
// To get absolute values must be multiplied by the image size.
export const getSelectedGridBlockAbsCoordinates = createSelector(
  getGridBlocksHash,
  getSelectedGridBlockId,
  getOverlayScaledImageSize,
  (hash, id, imageSize) => {
    const selectedBlock = hash[id];
    if (selectedBlock) {
      return new Rect(
        selectedBlock.rect.x * imageSize.width / 100,
        selectedBlock.rect.y * imageSize.height / 100,
        selectedBlock.rect.width * imageSize.width / 100,
        selectedBlock.rect.height * imageSize.height / 100
      );
    }
  }
);

export const getPageId = createSelector(getDisplayState, state => state.overlayConfig.id);

export const getSelectedArticleId = createSelector(getDisplayState, state => state.selectedArticleId);

export const getShowAllArticles = createSelector(getDisplayState, state => state.showAllArticles);

export const getShowTranslation = createSelector(getDisplayState, state => state.showTranslation);

export const getImageLoading = createSelector(getDisplayState, state => state.imageLoading);

export const getCollectionConfig = createSelector(getDisplayState, state => state.collectionConfig);
