import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GalleryLayoutComponent } from './gallery-layout/gallery-layout.component';

const routes: Routes = [
  {path: 'gallery', component: GalleryLayoutComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GalleryRoutingModule {}
