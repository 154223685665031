import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  GalleryActionTypes,
  GetCollectionConfig,
  GetCollectionConfigSuccess,
  GetCollectionConfigFailure,
  GetIssueData,
  GetIssueDataSuccess
} from './gallery.actions';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { EMPTY, fromEvent, Observable, of } from 'rxjs';
import { CollectionConfig } from '../../core/types/collectionConfig';
import { Issue } from '../../core/types/issue';
import { CollectionDataService } from '../../core/service/getjsondata';

@Injectable()
export class GalleryEffects {
  @Effect()
  readCollectionConfig$: Observable<Action> = this.actions$.pipe(
    ofType(GalleryActionTypes.GetCollectionConfig),
    switchMap((action: GetCollectionConfig) => this.collectionService.getCollectionConfig().pipe(
      map(x => {
        const collectionConfig: CollectionConfig = x as CollectionConfig;
        return new GetCollectionConfigSuccess(collectionConfig);
      }),
      catchError(error => of(new GetCollectionConfigFailure()))
    ))
  );

  @Effect()
  readIssueData$: Observable<Action> = this.actions$.pipe(
    ofType(GalleryActionTypes.GetIssueData),
    switchMap((action: GetIssueData) =>  this.collectionService.getIssueData(action.payload).pipe(
      map(x => {
        const issueData: Issue[] = x as Issue[];
        return new GetIssueDataSuccess(issueData);
      }),
      catchError(error => of(new GetCollectionConfigFailure()))
    ))
  );

  constructor(private http: HttpClient,
              private actions$: Actions,
              private collectionService: CollectionDataService
  ) {}
}
