import { Position, Rect, Size } from './types/overlay';

export function getRatioToWhole(value: number, whole: number) {
  return value / whole * 100;
}

export function calculateRect(start: Position, end: Position, whole: Size): Rect {
  const x = getRatioToWhole(Math.min(end.x, start.x), whole.width);
  const y = getRatioToWhole(Math.min(end.y, start.y), whole.height);
  const width = getRatioToWhole(Math.abs(end.x - start.x), whole.width);
  const height = getRatioToWhole(Math.abs(end.y - start.y), whole.height);
  return new Rect(x, y, width, height);
}
