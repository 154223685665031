import { IAppConfig } from './config.model';

export const appConfig: IAppConfig = {
    'appTitle': 'Correio de Africa [DEMO]',
    'appLanguage': 'en-US',
    'appCss': '',
    'defaultZoomLevel': 0.6,
    'languageList': [
        {
            'title': 'English',
            'value': 'en-US'
        },
        {
            'title': 'Português',
            'value': 'pt-PT'
        },
        {
            'title': 'Русский язык',
            'value': 'ru-RU'
        }
    ]
};
