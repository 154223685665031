import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { Overlay } from '../types/overlay';
import { CollectionConfig, IssueRef } from '../types/collectionConfig';
import { Issue } from '../types/issue';

@Injectable({
  providedIn: 'root',
})

export class PageDataService {
    private NEXT_PAGE_PATH = '/assets/translations/pages/bpmp_1924-09-10-p2.json';
    private PREV_PAGE_PATH = '/assets/translations/pages/bpmp_1924-09-10-p1.json';

    constructor(private http: HttpClient) {}

    getNextPage(): Observable<Overlay> {
        return this.http
            .get(this.NEXT_PAGE_PATH)
            .pipe(map(pageData => {
                const data: Overlay = pageData as Overlay;
                return data;
            }));
    }

    getPrevPage(): Observable<Overlay> {
        return this.http
            .get(this.PREV_PAGE_PATH)
            .pipe(map(pageData => {
                const data: Overlay = pageData as Overlay;
                return data;
            }));
    }
}

@Injectable({
  providedIn: 'root',
})

export class CollectionDataService {
  private COLLECTION_CONFIG_PATH = '/assets/translations/collections/collection_correio_de_africa.json';

  constructor(private http: HttpClient) {}

  getCollectionConfig(): Observable<CollectionConfig> {
    return this.http
      .get(this.COLLECTION_CONFIG_PATH)
      .pipe(map(collectionConfig => {
        const data: CollectionConfig = collectionConfig as CollectionConfig;
        return data;
      }));
  }

  getIssueData(urls: string[]): Observable<Issue[]> {
    return forkJoin(
      urls.map(url => this.http
      .get(url)
      .pipe(map(issueData => {
        const data: Issue = issueData as Issue;
        return data;
      }))));
  }
}
