import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../core/app-reducers';
import { GalleryState } from './gallery.reducer';

export interface AppStateWithGallery extends AppState {
  gallery: GalleryState;
}

const getGalleryState = createFeatureSelector<AppStateWithGallery, GalleryState>('gallery');

export const getCollectionConfig = createSelector(getGalleryState, state => state.collectionConfig);

export const getIssueData = createSelector(getGalleryState, state => state.issueData);
